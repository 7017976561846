import React, { useState, useEffect } from 'react';
import './App.css';
import confetti from 'canvas-confetti';

function App() {
  const [nombres, setNombres] = useState([]);
  const [nombresSeleccionados, setNombresSeleccionados] = useState([]);
  const [todos, setTodos] = useState([]);
  const [nombreActual, setNombreActual] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [fechaActual, setFechaActual] = useState('');

  useEffect(() => {
    const obtenerFechaActual = () => {
      const fecha = new Date().toLocaleDateString('es-CL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
      setFechaActual(fecha);
    };

    const fetchNombres = async () => {
      try {
        const response = await fetch('https://southamerica-east1-kubida.cloudfunctions.net/getNombres');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setNombres(data.filter(nombre => !nombre.vacaciones && !nombre.seleccionado).map(nombre => nombre.nombre));
        setNombresSeleccionados(data.filter(nombre => nombre.vacaciones || nombre.seleccionado).map(nombre => nombre.nombre));
        setTodos(data);
        setNombreActual(data.reduce((prev, current) => (prev.updatedAt._seconds > current.updatedAt._seconds) ? prev : current).nombre);
      } catch (error) {
        console.error("Error fetching nombres:", error);
      }
    };

    obtenerFechaActual();
    fetchNombres();
  }, []);

  const handleStartRotation = () => {
    let index = 0;
    const intervalId = setInterval(() => {
      setNombreActual(nombres[index % nombres.length]);
      index++;
    }, 100);

    setTimeout(() => {
      clearInterval(intervalId);
      const randomIndex = Math.floor(Math.random() * nombres.length);
      const nombreSeleccionado = nombres[randomIndex];

      const nuevosNombres = nombres.filter((_, i) => i !== randomIndex);
      setNombres(nuevosNombres);
      setNombresSeleccionados([...nombresSeleccionados, nombreSeleccionado]);
      // en la lista todos, buscar el nombre seleccionado y cambiar el campo seleccionado a true
      const nuevoTodos = todos.map(item => {
        if (item.nombre === nombreSeleccionado) {
          return { ...item, seleccionado: true };
        }
        return item;
      });
      setTodos(nuevoTodos);
      setNombreActual(nombreSeleccionado);
      seleccionarNombreAleatorio(nombreSeleccionado);
      launchConfetti();
    }, 5000);
  };

  const seleccionarNombreAleatorio = async (nombreSeleccionado) => {
    try {
      await fetch('https://southamerica-east1-kubida.cloudfunctions.net/selectNombre', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre: nombreSeleccionado, fecha: fechaActual }),
      });
    } catch (error) {
      console.error("Error selecting nombre:", error);
    }
  };

  const launchConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    try {
      await fetch('https://southamerica-east1-kubida.cloudfunctions.net/addNombre', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre: inputValue }),
      });
      setNombres([...nombres, inputValue]);
      setInputValue('');
    } catch (error) {
      console.error("Error adding nombre:", error);
    }
  };

  const handleDelete = async (index, source) => {
    const nombreAEliminar = source === 'nombres' ? nombres[index] : nombresSeleccionados[index];
    try {
      await fetch('https://southamerica-east1-kubida.cloudfunctions.net/deleteNombre', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre: nombreAEliminar }),
      });
      if (source === 'nombres') {
        const nuevosNombres = nombres.filter((_, i) => i !== index);
        setNombres(nuevosNombres);
      } else {
        const nuevosNombresSeleccionados = nombresSeleccionados.filter((_, i) => i !== index);
        setNombresSeleccionados(nuevosNombresSeleccionados);
      }
    } catch (error) {
      console.error("Error deleting nombre:", error);
    }
  };

  const onDragStart = (e, index, source) => {
    e.dataTransfer.setData('index', index.toString());
    e.dataTransfer.setData('source', source);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = async (e, destination) => {
    e.preventDefault();

    const index = e.dataTransfer.getData('index');
    const source = e.dataTransfer.getData('source');

    if (source === 'nombres' && destination === 'nombresSeleccionados') {
      const nombre = nombres[index];
      const nuevosNombres = nombres.filter((_, i) => i !== parseInt(index));
      setNombres(nuevosNombres);
      setNombresSeleccionados([...nombresSeleccionados, nombre]);
      await updateVacaciones(nombre, true);

    } else if (source === 'nombresSeleccionados' && destination === 'nombres') {
      const nombre = nombresSeleccionados[index];
      const nuevosNombresSeleccionados = nombresSeleccionados.filter((_, i) => i !== parseInt(index));
      setNombresSeleccionados(nuevosNombresSeleccionados);
      setNombres([...nombres, nombre]);
      await updateVacaciones(nombre, false);

    }
  };

  const updateVacaciones = async (nombre, vacaciones) => {
    try {
      await fetch('https://southamerica-east1-kubida.cloudfunctions.net/updateVacaciones', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre, vacaciones }),
      });
    } catch (error) {
      console.error("Error updating vacaciones:", error);
    }
  };
  return (
    <div className="app-container">
      <div className="contenido-principal">
        <h1>{nombreActual || 'Presiona "¿Quién lleva la sync?" para comenzar'}</h1>
        <p>TE TOCA LA SYNC el {fechaActual}</p>
      </div>
      <button className="primary" onClick={handleStartRotation}> Nouveau volontaire </button>
      <div className="contenido-secundario">
        <h3>Los candidatos</h3>
        <div className="nombres-agregados" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'nombres')}>
          {nombres.map((nombre, index) => (
            <div key={index} className="nombre-etiqueta" draggable onDragStart={(e) => onDragStart(e, index, 'nombres')}>{nombre} <button onClick={() => handleDelete(index, 'nombres')}>X</button></div>
          ))}
        </div>
        <h3>Hoy no juegan</h3>
        <div className="nombres-agregados" onDragOver={onDragOver} onDrop={(e) => onDrop(e, 'nombresSeleccionados')}>
          {nombresSeleccionados.map((nombre, index) => (
              
           <div key={index} className={`nombre-etiqueta ${todos.find(item => item.nombre === nombre).seleccionado ? 'seleccionado' : ''}`} draggable onDragStart={(e) => onDragStart(e, index, 'nombresSeleccionados')}>{nombre} <button onClick={() => handleDelete(index, 'nombresSeleccionados')}>X</button></div>

           
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Agrega un nombre"
            value={inputValue}
            onChange={handleChange}
          />
          <button type="submit">Agregar</button>
        </form>
      </div>
    </div>
  );
}

export default App;
